import {attr, controller, target, targets} from '@github/catalyst'

interface PolicyData {
  id?: number
  name: string
  policyConstraints: PolicyConstraint[]
  policyTargetType: string
}

interface AllowableValue {
  display_name: string
  name: string
  cpus: number
  displayText: string
}

interface PolicyConstraint {
  name: string
  value_type?: string
  enabled_value?: string
  maximum_value?: number
  minimum_value?: number
  allowed_values?: string[]
  allowable_values?: AllowableValue[]
}

const MachineTypesConstraintName = 'codespaces.allowed_machine_types'

function hideElement(element: HTMLElement | null): void {
  if (element !== null) {
    element.hidden = true
  }
}

function showElement(element: HTMLElement | null): void {
  if (element !== null) {
    element.hidden = false
  }
}

const TargetAllReposType = 'User'
const TargetSelectedReposType = 'Repo'

@controller
class CodespacesPolicyFormElement extends HTMLElement {
  @target policyNameFormGroup: HTMLElement
  @target policyNameShortNameError: HTMLElement
  @target policyNameLongNameError: HTMLElement
  @target constraintList: HTMLElement
  @target addConstraintInfo: HTMLElement
  @target addConstraintDropdownList: HTMLDetailsElement
  @target saveButton: HTMLButtonElement
  @target saveErrorElement: HTMLElement
  @target spinnerElement: HTMLElement

  @targets activeConstraintsListRows: HTMLElement[]
  @targets addConstraintButtons: HTMLButtonElement[]

  // Targets for MachineType constraint elements
  @target machineTypeConstraintElement: HTMLElement
  @target noSelectedAllowedValuesTextForMachineTypes: HTMLElement
  @target selectedAllowedValuesTextForMachineTypes: HTMLElement
  @targets constraintAllowableValueCheckboxesForMachineTypes: HTMLInputElement[]

  // Targets for Repository selection elements
  @target selectedRepositoriesCountTextElement: HTMLElement
  @target repositoriesTargetSelector: HTMLElement
  @target dynamicRepositorySelectionEl: HTMLElement
  @target allRepositoriesDescriptionEl: HTMLElement
  @target selectedRepositoriesDescriptionEl: HTMLElement

  // Required for data-* attributes
  @attr constraint = ''
  @attr addedConstraintName = ''
  @attr constraintButtonName = ''
  @attr constraintButtonInfo = ''
  @attr existingPolicy = ''
  @attr existingPolicyConstraints = ''
  @attr removedConstraintName = ''
  @attr selector = ''

  policyData: PolicyData = {
    name: '',
    policyTargetType: TargetAllReposType,
    policyConstraints: []
  }
  machineTypeConstraint: PolicyConstraint = {name: MachineTypesConstraintName}

  connectedCallback(): void {
    const machineTypeConstraintInfo = JSON.parse(this.machineTypeConstraintElement.getAttribute('data-value') as string)
    this.machineTypeConstraint = {
      ...machineTypeConstraintInfo,
      allowable_values: machineTypeConstraintInfo.allowable_values.map((value: {display_cpus: string}) => ({
        ...value,
        displayText: value.display_cpus
      }))
    }
    const existingPolicy = this.constraintList.getAttribute('data-existing-policy') as string

    if (existingPolicy === null) {
      return
    }

    this.policyData = {
      ...JSON.parse(existingPolicy),
      policyConstraints:
        JSON.parse(this.constraintList.getAttribute('data-existing-policy-constraints') as string) || [],
      policyTargetType: this.fetchPolicyTargetType()
    }
  }

  handlePolicyNameChange(event: Event): void {
    const currentTarget = event.currentTarget as HTMLInputElement
    this.policyData = {
      ...this.policyData,
      name: currentTarget.value.trim()
    }

    this.hidePolicyNameGroupErrors()
    this.updateSaveButton()
  }

  validatePolicyNameChange(event: Event): void {
    const currentTarget = event.currentTarget as HTMLInputElement

    if (currentTarget.value.trim().length === 0) {
      this.policyNameFormGroup.classList.add('errored')
      showElement(this.policyNameShortNameError)
      return
    }

    if (currentTarget.value.trim().length > 64) {
      this.policyNameFormGroup.classList.add('errored')
      showElement(this.policyNameLongNameError)
      return
    }

    this.hidePolicyNameGroupErrors()
  }

  private hidePolicyNameGroupErrors(): void {
    this.policyNameFormGroup.classList.remove('errored')
    hideElement(this.policyNameShortNameError)
    hideElement(this.policyNameLongNameError)
  }

  addConstraint(event: Event): void {
    const currentTarget = event.currentTarget as HTMLElement
    const constraint = JSON.parse(currentTarget.getAttribute('data-constraint-button-info') as string)

    if (constraint === null) {
      return
    }

    this.addConstraintToPolicyFormData(constraint)
    this.handleDisplayedDefaultsForPolicyConstraint(constraint)

    this.showConstraintListRow(constraint.name)
    this.hideAddConstraintButton(constraint.name)
    hideElement(this.addConstraintInfo)
    this.addConstraintDropdownList.open = false
    this.updateSaveButton()
  }

  private updateSaveButton(): void {
    if (this.policyData.policyConstraints.length > 0 && this.policyData.name) {
      this.saveButton.disabled = false
    } else {
      this.saveButton.disabled = true
    }
  }

  private addConstraintToPolicyFormData(constraint: PolicyConstraint): void {
    this.policyData = {
      ...this.policyData,
      policyConstraints: this.policyData.policyConstraints.concat({
        ...constraint,
        allowed_values: constraint.allowable_values?.map((value: {name: string}) => value.name)
      })
    }
  }

  private handleDisplayedDefaultsForPolicyConstraint(constraint: PolicyConstraint): void {
    switch (constraint.name) {
      case MachineTypesConstraintName:
        this.showAllAllowedValuesTextFor(MachineTypesConstraintName)
        this.checkAllAllowableValues(MachineTypesConstraintName)
        break
      default:
        null
    }
  }

  private handleDisplayingAllowedValuesTextForConstraint(constraintName: string, allowedValues: string[]): void {
    let allowedValuesTextElement: HTMLElement | null = null
    let noAllowedValuesTextElement: HTMLElement | null = null
    let constraint: PolicyConstraint | null = null

    switch (constraintName) {
      case MachineTypesConstraintName:
        allowedValuesTextElement = this.selectedAllowedValuesTextForMachineTypes
        noAllowedValuesTextElement = this.noSelectedAllowedValuesTextForMachineTypes
        constraint = this.machineTypeConstraint
        break
      default:
        return
    }

    if (allowedValues.length === 0) {
      showElement(noAllowedValuesTextElement)
      hideElement(allowedValuesTextElement)

      return
    }

    if (allowedValues.length > 0) {
      allowedValuesTextElement.textContent =
        constraint?.allowable_values
          ?.filter((value: {name: string}) => allowedValues.includes(value.name))
          .map((value: {displayText: string}) => value.displayText)
          .join(', ') || ''
      showElement(allowedValuesTextElement)
      hideElement(noAllowedValuesTextElement)
    }
  }

  private showAllAllowedValuesTextFor(constraintName: string): void {
    let allowedValuesText: HTMLElement | null = null
    let noAllowedValuesText: HTMLElement | null = null
    let constraint: PolicyConstraint | null = null

    switch (constraintName) {
      case MachineTypesConstraintName:
        allowedValuesText = this.selectedAllowedValuesTextForMachineTypes
        noAllowedValuesText = this.noSelectedAllowedValuesTextForMachineTypes
        constraint = this.machineTypeConstraint
        break
      default:
        return
    }

    allowedValuesText.textContent = constraint?.allowable_values?.map(value => value.displayText).join(', ') || ''
    showElement(allowedValuesText)
    hideElement(noAllowedValuesText)
  }

  private checkAllAllowableValues(constraintName: string): void {
    let checkboxesToCheck: HTMLInputElement[]

    switch (constraintName) {
      case MachineTypesConstraintName:
        checkboxesToCheck = this.constraintAllowableValueCheckboxesForMachineTypes
        break
      default:
        checkboxesToCheck = []
    }

    for (const checkbox of checkboxesToCheck) {
      checkbox.checked = true
    }
  }

  selectAllowableValueForConstraint(event: Event): void {
    const currentTarget = event.currentTarget as HTMLInputElement
    const constraintName = currentTarget.getAttribute('data-constraint-name') as string
    const newAllowedValue = currentTarget?.value
    const constraintIndex = this.findConstraintIndexInPolicyData(constraintName)
    const constraintIsStored = constraintIndex !== -1

    if (!constraintIsStored) {
      return
    }

    const currentConstraintStored = this.policyData.policyConstraints[constraintIndex]
    const currentNameStored = currentConstraintStored.allowed_values?.find(value => value === newAllowedValue)
    const currentAllowedValues = currentConstraintStored.allowed_values || []
    const updatedAllowedValues = currentNameStored
      ? currentAllowedValues.filter(value => value !== newAllowedValue)
      : currentAllowedValues.concat(newAllowedValue)

    this.policyData = {
      ...this.policyData,
      policyConstraints: [
        ...this.policyData.policyConstraints.slice(0, constraintIndex),
        {
          ...currentConstraintStored,
          allowed_values: updatedAllowedValues
        },
        ...this.policyData.policyConstraints.slice(constraintIndex + 1)
      ]
    }

    this.handleDisplayingAllowedValuesTextForConstraint(constraintName, updatedAllowedValues)
  }

  private findConstraintIndexInPolicyData(constraintName: string): number {
    return this.policyData.policyConstraints.findIndex(constraint => constraint.name === constraintName)
  }

  removeConstraint(event: Event): void {
    const currentTarget = event.currentTarget as HTMLElement
    const constraint = JSON.parse(currentTarget.getAttribute('data-removed-constraint-name') as string)

    if (constraint === null) {
      return
    }

    const constraintIdx = this.policyData.policyConstraints.findIndex(c => c.name === constraint.name)
    this.policyData = {
      ...this.policyData,
      policyConstraints: [
        ...this.policyData.policyConstraints.slice(0, constraintIdx),
        ...this.policyData.policyConstraints.slice(constraintIdx + 1)
      ]
    }

    this.hideConstraintListRow(constraint.name)
    this.showAddConstraintButton(constraint.name)

    if (this.policyData.policyConstraints.length === 0) {
      showElement(this.addConstraintInfo)
    }
    this.updateSaveButton()
  }

  private hideConstraintListRow(constraintName: string): void {
    for (const row of this.activeConstraintsListRows) {
      if (row.getAttribute('data-added-constraint-name') === constraintName) {
        hideElement(row)
      }
    }
  }

  private showConstraintListRow(constraintName: string): void {
    for (const row of this.activeConstraintsListRows) {
      if (row.getAttribute('data-added-constraint-name') === constraintName) {
        showElement(row)
      }
    }
  }

  private showAddConstraintButton(constraintName: string): void {
    for (const button of this.addConstraintButtons) {
      if (button.getAttribute('data-constraint-button-name') === constraintName) {
        showElement(button)
      }
    }
  }

  private hideAddConstraintButton(constraintName: string): void {
    for (const button of this.addConstraintButtons) {
      if (button.getAttribute('data-constraint-button-name') === constraintName) {
        hideElement(button)
      }
    }
  }

  selectRepositories(): void {
    const repoCheckboxes = this.querySelectorAll<HTMLInputElement>(
      'input[name="enable[]"][data-form-field-name="codespaces-policy-group-target-repo-ids"]'
    )
    const repoIds: number[] = []
    for (const checkbox of repoCheckboxes) {
      repoIds.push(parseInt(checkbox.value))
    }
    this.selectedRepositoriesCountTextElement.textContent = `${repoCheckboxes.length.toString()} selected`
    showElement(this.selectedRepositoriesCountTextElement)
  }

  changeRepositoriesTargetType(): void {
    const storedTarget = this.policyData.policyTargetType
    const currentTarget = this.fetchPolicyTargetType()

    if (storedTarget !== currentTarget) {
      this.policyData = {
        ...this.policyData,
        policyTargetType: currentTarget
      }
    }

    switch (currentTarget) {
      case TargetAllReposType:
        hideElement(this.dynamicRepositorySelectionEl)
        hideElement(this.selectedRepositoriesDescriptionEl)
        showElement(this.allRepositoriesDescriptionEl)
        break
      case TargetSelectedReposType:
        hideElement(this.allRepositoriesDescriptionEl)
        showElement(this.dynamicRepositorySelectionEl)
        showElement(this.selectedRepositoriesDescriptionEl)
        this.selectRepositories()
        break
    }
  }

  fetchPolicyTargetType(): string {
    const repositorySelectionText = this.repositoriesTargetSelector
      .querySelector('[data-selector="policy-selection"]')
      ?.textContent?.trim()

    switch (repositorySelectionText) {
      case 'All repositories':
        return TargetAllReposType
      case 'Selected repositories':
        return TargetSelectedReposType
      default:
        return TargetAllReposType
    }
  }

  async savePolicy(event: Event): Promise<void> {
    showElement(this.spinnerElement)
    const currentTarget = event.currentTarget as HTMLElement
    const csrfToken = currentTarget.getAttribute('data-csrf') || ''
    const url = currentTarget.getAttribute('data-submit-url') || ''
    const redirectUrl = currentTarget.getAttribute('data-redirect-url') || ''
    let method = 'post'
    const formData = this.buildFormData(csrfToken)

    if (this.policyData.id) {
      method = 'put'
    }

    try {
      const response = await fetch(url, {
        method,
        body: formData,
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      if (response.status === 200) {
        window.location.href = redirectUrl
      } else {
        hideElement(this.spinnerElement)
        showElement(this.saveErrorElement)
      }
    } catch (err) {
      hideElement(this.spinnerElement)
      showElement(this.saveErrorElement)
    }
  }

  private buildFormData(csrfToken: string): FormData {
    const formData = new FormData()

    // eslint-disable-next-line github/authenticity-token
    formData.set('authenticity_token', csrfToken)

    formData.append(`policy_group[name]`, this.policyData.name)
    formData.append(
      `policy_group[all_repositories]`,
      JSON.stringify(this.policyData.policyTargetType === TargetAllReposType)
    )

    const repoCheckboxes = document.querySelectorAll<HTMLInputElement>(
      'input[name="enable[]"][data-form-field-name="codespaces-policy-group-target-repo-ids"]'
    )
    for (const checkbox of repoCheckboxes) {
      formData.append(`policy_group[repository_ids][]`, checkbox.value)
    }

    for (const policyConstraint of this.policyData.policyConstraints) {
      formData.append(`policy_group[constraints][][name]`, policyConstraint.name)
      if (policyConstraint.allowed_values) {
        for (const constraintValue of policyConstraint.allowed_values) {
          formData.append(`policy_group[constraints][][value][]`, constraintValue)
        }
      }
    }
    return formData
  }
}
